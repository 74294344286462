import React, { useEffect, useState } from "react";
import "./sub.css";
import "../Sub/common.css";
import PCD from "../../images/logo.png";
import { Button, CloseButton, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  getCartlist,
  getProducts,
  getSocialMediaSettings,
  updateCart,
} from "../../reducers/commonReducer";
import sumBy from "lodash/sumBy";
import { checkout, dashboard, home, login, userDashboard } from "../../const";
import { getUserProfile } from "../../reducers/orderDetailsReducer";

const Header = () => {
  const [lgShow, setLgShow] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    !!localStorage.getItem("x-auth-token") && dispatch(getCartlist());
    !!localStorage.getItem("x-auth-token") && dispatch(getUserProfile());
    dispatch(getSocialMediaSettings());
  }, []);
  const {
    getCartlistData,
  } = useSelector((state) => state.commonReducer);
  let getCartDetails = JSON.parse(localStorage.getItem("cartItem"));
  let cartDetails = sumBy(
    !!getCartlistData?.list && getCartlistData?.list,
    function (o) {
      return o.quantity;
    }
  );
  let localcartDetails = sumBy(
    !!getCartDetails && getCartDetails,
    function (o) {
      return o.quantity;
    }
  );
  const handleClose = () => {
    setLgShow(false);
  };

  return (
    <>
      <section className="yellow-header header-main botm-border">
        <div className="container-fluid">
          <div className="row p-0 m-0">
            <div className="col-md-4 small-txt mbl-hide">
            
            </div>
         
          
          </div>
        </div>
      </section>
      {/* logo menu */}
      <section className="yellow-header header-main">
        <div className="container-fluid">
          <div className="row pt-3 pb-3 mbl-padding-0 align-item-center">
            <Col
              xs={6}
              md={3}
              lg={3}
              className="weblogo justify-content-center mbl-margin-0"
            >
              <Link to={home}>
                <img className="logo" src={PCD} />
              </Link>
            </Col>
            <Col className="col-md-5 col-lg-5 nav-links mbl-hide ">
  <nav className="nav gap-3 justify-content-center ">
    <a className="nav-link text-white hright" href="/">HOME</a>
    <a className="nav-link text-white hright" href="/about">ABOUT</a>
    <a className="nav-link text-white hright" href="/contact">CONTACT</a>
    <a className="nav-link text-white hright" href="/shop">PRODUCTS</a>
  </nav>
</Col>



      <Col xs={6} md={2} lg={3} className="d-flex justify-content-center align-items-center headergap p-2 ">
        <Link to={(localStorage.getItem("x-auth-token") && localStorage.getItem("slug") === "customer") ? "/user-Dashboard" : "/login"} className="header-icon">
          <i className={`text-white hright bi ${localStorage.getItem("x-auth-token") ? "bi-person-fill" : "bi-lock-fill"}`}></i>
          {(localStorage.getItem("x-auth-token") && localStorage.getItem("slug") === "customer") ? <span className=" text-white hright">Profile</span> : <span className=" text-white hright">Login</span>}
        </Link>

        
       {localStorage.getItem("x-auth-token") && <Button variant="danger" onClick={() => {
          localStorage.clear();
          navigate(`/`)
        }}>Logout</Button>}
        {/* <Link to="/wishlist" className="header-icon position-relative">
          <i className="bi bi-heart text-white hright"></i>
          <span className="badge rounded-pill bg-success position-absolute top-0 start-100 translate-middle">
            1
          </span>
        </Link> */}

        <Link to={getCartlistData?.list?.length >= 1 ? "/checkout" : "/cart"} className="header-icon position-relative">
          <i className="bi bi-cart text-white hright"></i>
          <span className="badge rounded-pill bg-success position-absolute top-0 start-100 translate-middle">
            {getCartlistData?.list?.length || 0}
          </span>
        </Link>

        <span className="header-icon fw-bold ms-2 text-white hright">$00.00</span>

        {/* Start Checkout Popup */}
        <Modal size="lg" show={lgShow} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Your Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="cart" className="table table-hover table-condensed">
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>Product</th>
                  <th style={{ width: "10%" }}>Price</th>
                  <th style={{ width: "8%" }}>Quantity</th>
                  <th style={{ width: "22%" }} className="text-center">Subtotal</th>
                  <th style={{ width: "10%" }} />
                </tr>
              </thead>
              <tbody>
                {!!getCartlistData?.list &&
                  getCartlistData.list.map((data, index) => (
                    <tr key={index}>
                      <td data-th="Product">
                        <div className="row">
                          <div className="col-sm-12 hidden-xs">
                            <img src={data?.proudct?.images?.[0]} alt="..." className="img-responsive popup-product" />
                          </div>
                        </div>
                      </td>
                      <td data-th="Price">$ {data?.proudct?.sale}</td>
                      <td data-th="Quantity">
                        <input
                          type="number"
                          className="form-control text-center"
                          value={data.quantity}
                          onChange={async (e) => {
                            await dispatch(updateCart({ id: data.id, quantity: +e.target.value }));
                            dispatch(getCartlist());
                          }}
                        />
                      </td>
                      <td data-th="Subtotal" className="text-center phone-center">
                        $ {data?.proudct?.sale * data.quantity}
                      </td>
                      <td className=" text-center phone-center">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={async () => {
                            await dispatch(updateCart({ id: data.id, quantity: 0 }));
                            setTimeout(() => dispatch(getCartlist()), 500);
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Link to="/home" className="btn btn-warning">Continue Shopping</Link>
                  </td>
                  <td colSpan={2} className="hidden-xs text-center">
                    <strong>Total</strong>
                  </td>
                  <td className="hidden-xs text-center">
                    <strong>
                      ${" "}
                      {getCartlistData?.list?.reduce((sum, item) => sum + item?.proudct?.sale * item.quantity, 0) || 0}
                    </strong>
                  </td>
                  <td>
                    <Link to="/checkout" className="btn btn-warning btn-block">Checkout</Link>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Modal.Body>
        </Modal>
        {/* End Checkout Popup */}
      </Col>
          </div>
        </div>
      </section>
    
      <section>
        <Row>
          <Col className="col-sx-12 col-sm-12  desktop-hide bg-dark p-2 ">
            <div className=" justify-content-center ">
            <nav className="nav gap-3 justify-content-center ">
    <a className="nav-link text-white hright" href="/">HOME</a>
    <a className="nav-link text-white hright" href="/about">ABOUT</a>
    <a className="nav-link text-white hright" href="/contact">CONTACT</a>
    <a className="nav-link text-white hright" href="/shop">PRODUCTS</a>
  </nav>
            </div>
          </Col>
        </Row>
      </section>
      {/* {!!getHomePageSettingList?.home?.floating_icon &&
        getHomePageSettingList.home.floating_icon === true && (
          <ul id="social_side_links" className="side-social-links">
            {getSocialMediaSettingsData?.whatsapp && (
              <li>
                <a
                  href={`https://api.whatsapp.com/send?phone=+91${getSocialMediaSettingsData?.whatsapp}&text=Hi. I am intrested in Your Services..&source=&data=&app_absent=`}
                  target="_blank"
                >
                  <img src={whatsapp} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.facbook && (
              <li>
                <a href={getSocialMediaSettingsData.facbook} target="_blank">
                  <img src={facebook} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.instagram && (
              <li>
                <a href={getSocialMediaSettingsData.instagram} target="_blank">
                  <img src={instagram} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.twitter && (
              <li>
                <a href={getSocialMediaSettingsData.twitter} target="_blank">
                  <img src={twitter} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.youtube && (
              <li>
                <a href={getSocialMediaSettingsData?.youtube} target="_blank">
                  <img src={youtube} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.linkdin && (
              <li>
                <a href={getSocialMediaSettingsData.linkdin} target="_blank">
                  <img src={linked} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.mail && (
              <li>
                <a href={`mailto:${getSocialMediaSettingsData.mail}`}>
                  <img src={mail} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.phone && (
              <li>
                <a href={`tel:+91${getSocialMediaSettingsData.phone}`}>
                  <img src={phone} />
                </a>
              </li>
            )}
            {getSocialMediaSettingsData?.indiamart && (
              <li>
                <a href={getSocialMediaSettingsData.indiamart} target="_blank">
                  <img src={indiamart} />
                </a>
              </li>
            )}
          </ul>
        )} */}
    </>
  );
};

export default Header;
